import * as React from 'react';
import PropTypes from 'prop-types';
import './pagetitle.style.scss';

const PageTitle = ({ pageTitle, pageTagline, pageHighlight, pageClone }) => (
  <section className='page-title'>
    <div className='page-title__label'>
      {pageTitle}
      <span className='dot'>.</span>
    </div>
    <h1 className='page-title__tagline'>
      {pageTagline}
      <span className='line'>{pageHighlight}</span>
    </h1>
    <div className='page-title__clone'>{pageClone}</div>
  </section>
);

PageTitle.propTypes = {
  pageTitle: PropTypes.string,
  pageTagline: PropTypes.string,
  pageClone: PropTypes.string,
  pageHighlight: PropTypes.string,
};

PageTitle.defaultProps = {
  pageTitle: '',
  pageTagLine: '',
  pageClone: '',
  pageHighlight: '',
};

export default PageTitle;
