import * as React from 'react';
import { Link } from 'gatsby';
import PageTitle from '../components/pagetitle';

const NotFoundPage = () => (
  <main id='main' class='site-main'>
    <PageTitle
      pageTitle='Not Found'
      pageTagline='The page you were looking for was '
      pageHighlight='Not Found'
      pageClone='404'
    />
    <div class='page-content'>
      <div class='wrapper'>
        <p>
          Perhaps it was stolen, the internet is a scary place. Maybe try again
          incase someone returned it or visit the homepage and start from there.
          It is a pretty good homepage you really should take a look at it.
        </p>

        <div class='btn'>
          <Link
            title='Back to homepage'
            class='btn cta__btn
          transform-scale-h'
            to='/'
          >
            Back to homepage
          </Link>
        </div>
      </div>
    </div>
  </main>
);

export default NotFoundPage;
